@import "solar/Tokens.scss";

.component {
  font-size: $sol-text-3;
}

.grid {
  @media (min-width: $sol-breakpoint-lg) {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }
}

.links {
  display: inline-grid;
  grid-template-columns: repeat(4, auto);
  margin-top: $sol-space-sm;
  column-gap: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: 0;
  }
}

.logoLink {
  display: inline-block;
}
