@import "solar/Tokens.scss";

.list {
  display: inline-grid;
  row-gap: $sol-space-xs;
  align-content: start;
}

.header {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: 600;
  line-height: $sol-text-line-base;
  text-indent: 0;
}

.divider {
  height: 1px;
  margin-top: $sol-space-md;
  margin-bottom: $sol-space-md;
  background-color: $sol-color-gray-light;

  @media (min-width: $sol-breakpoint-lg) {
    display: none;
  }
}
