@import "solar/Tokens.scss";

$size-thumbnail: 64px;

.link {
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-column-gap: $sol-space-sm;
  align-items: center;
  font-weight: 500;
}

.thumbnail {
  position: relative;
  overflow: hidden;
  width: $size-thumbnail;
  height: $size-thumbnail;
  border-radius: $sol-border-radius-sm;
  background-color: $sol-color-gray-dark;
}
