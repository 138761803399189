@use "sass:math";
@import "solar/Tokens.scss";

$sol-btn-height-sm: 40px;
$sol-btn-height-md: 46px;
$sol-btn-height-lg: 54px;
$sol-btn-padding-x-sm: 1rem;
$sol-btn-padding-x-md: 1.4rem;
$sol-btn-padding-x-lg: 1.5rem;
$sol-btn-border-width: 2px;

.component {
  position: relative;
}

.link {
  font-weight: 500;
  text-align: left; // Fixes user agent center align with Truncate component
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.default {
  display: inline-flex;
  justify-content: center;
  border: $sol-btn-border-width solid transparent;
  background-color: transparent;
  color: $sol-color-black;
  font-weight: 500;
  font-family: $sol-font-family;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  place-items: center;

  &:disabled,
  &.disabled {
    &:not(.loading) {
      opacity: 0.25;
      cursor: not-allowed;
    }
  }

  &.loading {
    cursor: not-allowed;
  }
}

.small {
  height: $sol-btn-height-sm;
  padding: 0 $sol-btn-padding-x-sm;
  border-radius: $sol-border-radius-half;
  font-size: $sol-text-2;
}

.medium {
  height: $sol-btn-height-md;
  padding: 0 $sol-btn-padding-x-md;
  border-radius: $sol-border-radius-half;
  font-size: $sol-text-1;
}

.large {
  height: $sol-btn-height-lg;
  padding: 0 $sol-btn-padding-x-lg;
  border-radius: $sol-border-radius-half;
  font-size: $sol-title-5-size-responsive;
}

.black {
  border-color: $sol-color-black;
  background-color: $sol-color-black;
  color: $sol-color-white;

  &:hover,
  &:active,
  &:focus {
    color: $sol-color-white;
  }

  &.ghost {
    border-color: $sol-color-black;
    background-color: transparent;
    color: $sol-color-black;

    &:hover,
    &:active,
    &:focus {
      color: $sol-color-black;
    }
  }
}

.white {
  border-color: $sol-color-white;
  background-color: $sol-color-white;
  color: $sol-color-black;

  &:hover,
  &:active,
  &:focus {
    color: $sol-color-black;
  }

  &.ghost {
    border-color: $sol-color-white;
    background-color: transparent;
    color: $sol-color-white;

    &:hover,
    &:active,
    &:focus {
      color: $sol-color-white;
    }
  }
}

.off-white {
  border-color: $sol-color-off-white;
  background-color: $sol-color-off-white;
  color: $sol-color-black;

  &:hover,
  &:active,
  &:focus {
    color: $sol-color-black;
  }

  &.ghost {
    border-color: $sol-color-off-white;
    background-color: transparent;
    color: $sol-color-off-white;

    &:hover,
    &:active,
    &:focus {
      color: $sol-color-off-white;
    }
  }
}

.orange {
  border-color: $sol-color-orange;
  background-color: $sol-color-orange;
  color: $sol-color-white;

  &:hover,
  &:active,
  &:focus {
    color: $sol-color-white;
  }

  &.ghost {
    border-color: $sol-color-orange;
    background-color: transparent;
    color: $sol-color-orange;

    &:hover,
    &:active,
    &:focus {
      color: $sol-color-orange;
    }
  }
}

.lime {
  border-color: $sol-color-lime;
  background-color: $sol-color-lime;
  color: $sol-color-black;

  &:hover,
  &:active,
  &:focus {
    color: $sol-color-black;
  }

  &.ghost {
    border-color: $sol-color-lime;
    background-color: transparent;
    color: $sol-color-lime;

    &:hover,
    &:active,
    &:focus {
      color: $sol-color-lime;
    }
  }
}

.link {
  font-weight: 500;
  text-decoration: underline;
  padding: 0;
}

.block {
  display: block;
  width: 100%;
}

.responsive {
  display: block;
  width: 100%;

  @media (min-width: $sol-breakpoint-lg) {
    display: inline-block;
    width: auto;
  }
}

.hide {
  opacity: 0;
}

.dots {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  cursor: default;
  place-content: center;
}

.iconed {
  display: inline-grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: $sol-space-xs;
}

.small .iconed {
  column-gap: $sol-space-2xs;
}

.icon {
  display: inline-grid;
  place-items: center;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
