@import "solar/Tokens.scss";

.component {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-indent: 0;
}

.link {
  font-weight: 400;
  line-height: $sol-text-line-base;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
