@import "solar/Tokens.scss";

.component {
  padding-top: $sol-space-xs;
  padding-bottom: $sol-space-xs;
  background-color: $sol-color-forest;
  color: $sol-color-white;
  font-weight: 500;
  font-size: $sol-text-2;
  line-height: $sol-text-line-sm;
  text-align: left;

  a {
    color: $sol-color-white;
    text-decoration: underline;

    &:hover {
      color: $sol-color-white;
      text-decoration: none;
    }
  }
} // .component
