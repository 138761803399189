@import "solar/Tokens.scss";

$size-icon: 32px;

.component {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
  margin-top: $sol-space-md;
  margin-bottom: $sol-space-md;
  padding-top: $sol-space-sm;
  padding-bottom: $sol-space-sm;
  border-top: 1px solid $sol-color-gray;
  border-bottom: 1px solid $sol-color-gray;
  text-align: left;
  column-gap: $sol-space-sm;
}

.bar {
  width: 5px;
  height: 100%;
  background: $sol-color-orange;
}

.title {
  font-weight: 600;
}

.text {
  margin-top: $sol-space-2xs;
  line-height: $sol-text-line-sm;
}
