@import "solar/Tokens.scss";

$size-button-chat-sm: 46px;
$size-button-chat-lg: 56px;

$offset-size-sm: $sol-space-sm;
$offset-size-lg: $sol-space-lg;

$sol-btn-height-md: 46px;
$sol-btn-padding-x-md: 1.4rem;

.overlay {
  position: fixed;
  right: $offset-size-sm;
  bottom: $offset-size-sm;
  z-index: $sol-z-chat;
  display: grid;
  width: $size-button-chat-sm;
  height: $size-button-chat-sm;
  border-radius: $sol-border-radius-half;
  background-color: $sol-color-orange;
  color: $sol-color-white;
  filter: $sol-shadow-dropdown;
  place-items: center;

  @media (min-width: $sol-breakpoint-lg) {
    right: $offset-size-lg;
    bottom: $offset-size-lg;
    width: $size-button-chat-lg;
    height: $size-button-chat-lg;
  }
}

.ghost {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: $sol-space-xs;
  align-items: center;
  justify-content: center;
  height: $sol-btn-height-md;
  padding: 0 $sol-btn-padding-x-md;
  border: solid 2px $sol-color-black;
  border-radius: $sol-border-radius-half;
  background-color: transparent;
  color: $sol-color-black;
  font-weight: 500;
  font-size: $sol-text-1;

  &:hover,
  &:active,
  &:focus {
    color: $sol-color-black;
  }
}
