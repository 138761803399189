@use "sass:math";
@import "solar/Tokens.scss";

$size-before: 32px;

.component {
  position: relative;
  display: grid;
  cursor: pointer;
  place-items: center;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: $size-before;
    height: $size-before;
    border-radius: $sol-border-radius-half;
    transform: translate(-50%, -50%);
  }
} // .component

.overlay {
  &::after {
    z-index: -1;
    background: rgba($sol-color-white, 0.35);
  }
}
