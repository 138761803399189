@import "solar/Tokens.scss";

.component {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  padding: ($sol-space-sm * 0.8) $sol-space-sm;
  font-weight: 500;
  text-align: left;
}

.button {
  border: 1px solid $sol-color-gray-light;
  border-radius: $sol-card-border-radius-sm;
}

.link {
  border-top: 1px solid $sol-color-gray-light;

  &:first-of-type {
    border-top: 0;
  }
}
