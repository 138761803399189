@import "solar/Tokens.scss";

$size-icon: 32px;

$size-padding-x: $sol-space-xs * 1.25;
$size-padding-y: $sol-space-xs;

.component {
  position: absolute;
  bottom: $sol-space-sm;
  left: 50%;
  z-index: $sol-z-alert-toast;
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  padding-top: $size-padding-y;
  padding-right: $size-padding-x;
  padding-bottom: $size-padding-y;
  padding-left: $size-padding-x;
  border-radius: $sol-border-radius-sm;
  background-color: $sol-color-white;
  filter: $sol-shadow-dropdown;
  transform: translateX(-50%);
}

.icon {
  margin-right: $sol-space-xs;
}

.text {
  font-size: $sol-text-1;
  line-height: $sol-text-line-sm;
}
