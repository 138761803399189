@import "solar/Tokens.scss";

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $sol-z-modal-dialog;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $sol-z-modal-backdrop;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba($sol-color-black, 0.5);
}

.content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $sol-z-modal-content;
  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
  place-items: center;
}

.box {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  max-height: 100%;
  background: $sol-color-white;
  pointer-events: auto;

  @media (min-width: $sol-breakpoint-lg) {
    position: relative;
    width: auto;
    min-width: $sol-modal-box-min-width;
    max-width: $sol-modal-box-max-width;
    max-height: 90vh;
    margin-right: $sol-space-lg;
    margin-left: $sol-space-lg;
    padding-bottom: 0;
    border-radius: $sol-border-radius-md;
  }
}

.scroll {
  position: relative;
  overflow-y: auto;
}

.close {
  align-self: center;
}

.controls {
  z-index: $sol-z-modal-controls;
  border-bottom: 1px solid $sol-color-gray-light;

  &.overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border: 0;
  }
} // .controls

.navigation {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-top: $sol-space-sm;
  margin-bottom: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-md;
  }
}

.title {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

.fixed {
  width: 100%;

  @media (min-width: $sol-breakpoint-lg) {
    width: $sol-modal-box-width-md;
  }
}
