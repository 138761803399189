@import "solar/Tokens.scss";
$animation-duration: 0.3s;

.component {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $sol-z-dropdown-menu;
  overflow-y: scroll;
  width: 100%;
  padding-bottom: $sol-space-md;
  background: $sol-color-white;
  color: $sol-color-black;
  opacity: 0;
  visibility: hidden;
  transition: opacity $animation-duration ease-in-out,
    transform $animation-duration ease-in-out, visibility 0s $animation-duration;

  &.showing {
    opacity: 1;
    visibility: inherit;
    transition: opacity $animation-duration ease-in-out,
      transform $animation-duration ease-in-out, visibility 0s 0s;
  }
}

.header {
  display: grid;
  align-content: center;
  justify-content: flex-end;
  width: 100%;
  height: 66px;
}

.links {
  margin: 0;
  padding: 0 0 0 23px;
  list-style-type: none;
  font-weight: 700;
  font-size: 26px;
  text-indent: 0;
}

.secondary {
  font-weight: 300;
}

.divider {
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: $sol-color-gray-light;
}

.link {
  display: block;
  padding-top: $sol-space-xs;
  padding-bottom: $sol-space-xs;
  font-weight: 700;
  text-align: left;
  text-decoration: none;
}

.call {
  display: grid;
  grid-row-gap: $sol-space-xs;
  padding-left: 23px;
  color: $sol-color-neutral-2;
  font-size: 15px;
  text-align: left;
}

.phone {
  color: $sol-color-black;
  font-weight: 500;
  font-size: 17px;
}
