@import "solar/Tokens.scss";

.modal {
  @media (min-width: $sol-breakpoint-lg) {
    max-width: $sol-modal-box-width-md;
  }
}

.section {
  overflow: hidden;
  margin-top: $sol-space-sm;
  border: 1px solid $sol-color-gray-light;
  border-radius: $sol-card-border-radius-sm;
}

.divider {
  height: 1px;
  margin-top: $sol-space-sm;
  line-height: 0;
}
