// Global reset

* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
caption,
th,
td {
  vertical-align: middle;
  font-weight: normal;
  text-align: left;
}
q,
blockquote {
  quotes: none;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}
a img {
  border: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

// Forms. If no forms, remove these bad boys
button,
input {
  line-height: normal;
}
button,
input,
select,
textarea {
  vertical-align: baseline;
  *vertical-align: middle;
  margin: 0;
  font-size: 100%;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  *overflow: visible;
  cursor: pointer;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
textarea {
  vertical-align: top;
  overflow: auto;
  resize: vertical;
}

// Clear out the default button styles
button {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  outline: inherit;
  font: inherit;
  cursor: pointer;
}

// Clear the default outline settings
*:focus {
  outline: none !important;
}
