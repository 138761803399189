@import "solar/Tokens.scss";

.grid {
  min-height: 60vh;
  padding-top: $sol-space-md;
  padding-bottom: $sol-space-md;

  @media (min-width: $sol-breakpoint-lg) {
    padding-top: $sol-space-lg;
    padding-bottom: $sol-space-lg;
  }
}
