@import "solar/Tokens.scss";

.component {
  display: grid;
  row-gap: $sol-space-xs;
  padding-top: $sol-space-md;
  padding-right: $sol-space-lg;
  padding-bottom: $sol-space-md;
  padding-left: $sol-space-lg;
  border-radius: $sol-border-radius-md;
  background-color: $sol-color-brown;
  color: $sol-color-off-white;

  @media (min-width: $sol-breakpoint-lg) {
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: $sol-space-xs;
  }
}
