@import "solar/Tokens.scss";

.component {
  height: 100%; // rt: pretty sure we need this for react-dates small screen, but it's causing issues with modal navigation
  padding-right: $sol-space-sm;
  padding-left: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $sol-space-md;
    padding-left: $sol-space-md;
  }
}
