@import "solar/Tokens.scss";

.component {
  display: grid;
  grid-template-columns: 1fr auto;
  border-radius: $sol-border-radius-sm;
  overflow: hidden;
}

.input {
  display: block;
  overflow: hidden;
  padding-top: $sol-space-sm;
  padding-bottom: $sol-space-sm;
  border: 0;
  font-size: $sol-text-1;
  font-family: $sol-font-family;
  appearance: none; // Removes inner shadow for iOS
  padding-right: $sol-space-sm;
  padding-left: $sol-space-sm;
  background: transparent;

  // Fixes autofill background color
  &:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }
}

.button {
  display: grid;
  align-items: center;
  margin-left: $sol-space-2xs;
  margin-right: $sol-space-sm;
}

.light {
  border: 1px solid $sol-color-gray;
  background: $sol-color-white;

  &:focus-within {
    border-color: $sol-color-black;
    box-shadow: inset 0 0 0 1px $sol-color-black;
  }

  &.error {
    border-color: $sol-color-orange;

    &:focus-within {
      border-color: $sol-color-orange;
      box-shadow: inset 0 0 0 1px $sol-color-orange;
    }
  }

  .button {
    color: $sol-color-black;
  }
} // .light

.dark {
  border: 1px solid rgba($sol-color-white, 0.1);
  background: transparent;
  color: $sol-color-off-white;

  &:focus-within {
    border-color: $sol-color-off-white;
  }

  &.error {
    border-color: $sol-color-orange;

    &:focus-within {
      border-color: $sol-color-orange;
    }
  }

  .button {
    color: $sol-color-off-white;
  }
} // .dark

.transparent {
  border: 1px solid $sol-color-gray-light;
  background: transparent;
  color: $sol-color-black;

  &:focus-within {
    border-color: $sol-color-black;
    box-shadow: inset 0 0 0 1px $sol-color-black;
  }

  &.error {
    border-color: $sol-color-orange;

    &:focus-within {
      border-color: $sol-color-orange;
      box-shadow: inset 0 0 0 1px $sol-color-orange;
    }
  }

  .button {
    color: $sol-color-black;
  }
} // .dark
