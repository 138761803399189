@import "solar/Tokens.scss";

.component {
  // rt: required for floating on hero in trip
  position: relative;
  z-index: $sol-z-navbar;
  color: $sol-color-black;
}

.sticky {
  position: sticky;
  top: 0;
  background: $sol-color-white;

  @media (min-width: $sol-breakpoint-lg) {
    position: relative;
    top: initial;
  }
}

.borderBottom {
  border-bottom: 1px solid $sol-color-gray-light;
}
