@import "solar/Tokens.scss";

.component {
  width: 100%;

  @media (min-width: $sol-breakpoint-lg) {
    width: $sol-modal-box-width-md;
  }
}

.list {
  display: grid;
  grid-row-gap: $sol-space-sm;
  list-style: none;
}
