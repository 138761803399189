// Global components
@import "solar/Reset.scss";
@import "solar/Tokens.scss";
@import "solar/Fonts.scss";

// Global styles
html,
body {
  color: $sol-color-black;
  font-weight: 400;
  font-size: $sol-text-1;
  font-family: $sol-font-family;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  background-color: transparent;
  color: $sol-color-black;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $sol-color-black;
    text-decoration: none;
  }
}
