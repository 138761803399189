@import "solar/Tokens.scss";

$size-button: 32px;
$height-component: 66px;

.component {
  position: relative;
  display: grid;
  align-content: center;
  width: 100%;
  height: $height-component;

  &.open {
    background: $sol-color-white;

    .grid {
      color: $sol-color-black;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-right: $sol-space-sm;
  padding-left: $sol-space-sm;
}

.primary,
.secondary {
  display: grid; // rt: added to fix child image links
  align-items: center;
}

.actions {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: $sol-space-xs;
}

.button {
  position: relative;
  display: grid;
  min-width: 24px;
  margin-right: -$sol-space-2xs;
  cursor: pointer;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: $size-button;
    height: $size-button;
    transform: translate(-50%, -50%);
  }
}

.search {
  margin-right: $sol-space-sm;
}
