// Global fonts
// All font changes must also be made in transactional emails links and in the _document.jsx font preload

// ITC Cushing
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "ITC Cushing";
  src: url("/fonts/itc-cushing/ITCCushingBook.woff2") format("woff2"),
    url("/fonts/itc-cushing/ITCCushingBook.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-style: italic;
  font-family: "ITC Cushing";
  src: url("/fonts/itc-cushing/ITCCushingBookItalic.woff2") format("woff2"),
    url("/fonts/itc-cushing/ITCCushingBookItalic.woff") format("woff");
  font-display: swap;
}

// Lettera Text
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Lettera Text";
  src: url("/fonts/lettera-text/LetteraTextLLSub-Regular.woff2") format("woff2"),
    url("/fonts/lettera-text/LetteraTextLLSub-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-style: italic;
  font-family: "Lettera Text";
  src: url("/fonts/lettera-text/LetteraTextLLSub-Italic.woff2") format("woff2"),
    url("/fonts/lettera-text/LetteraTextLLSub-Italic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: "Lettera Text";
  src: url("/fonts/lettera-text/LetteraTextLLSub-Medium.woff2") format("woff2"),
    url("/fonts/lettera-text/LetteraTextLLSub-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-style: italic;
  font-family: "Lettera Text";
  src: url("/fonts/lettera-text/LetteraTextLLSub-MediumItalic.woff2")
      format("woff2"),
    url("/fonts/lettera-text/LetteraTextLLSub-MediumItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: "Lettera Text";
  src: url("/fonts/lettera-text/LetteraTextLLSub-Bold.woff2") format("woff2"),
    url("/fonts/lettera-text/LetteraTextLLSub-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 600;
  font-style: italic;
  font-family: "Lettera Text";
  src: url("/fonts/lettera-text/LetteraTextLLSub-BoldItalic.woff2")
      format("woff2"),
    url("/fonts/lettera-text/LetteraTextLLSub-BoldItalic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "Lettera Text";
  src: url("/fonts/lettera-text/LetteraTextLLSub-Black.woff2") format("woff2"),
    url("/fonts/lettera-text/LetteraTextLLSub-Black.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-style: italic;
  font-family: "Lettera Text";
  src: url("/fonts/lettera-text/LetteraTextLLSub-BlackItalic.woff2")
      format("woff2"),
    url("/fonts/lettera-text/LetteraTextLLSub-BlackItalic.woff") format("woff");
  font-display: swap;
}

// Lettera Mono
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Lettera Mono";
  src: url("/fonts/lettera-mono/LetteraMonoLLSub-Regular.woff2") format("woff2"),
    url("/fonts/lettera-mono/LetteraMonoLLSub-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-style: italic;
  font-family: "Lettera Mono";
  src: url("/fonts/lettera-mono/LetteraMonoLLSub-Italic.woff2") format("woff2"),
    url("/fonts/lettera-mono/LetteraMonoLLSub-Italic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: "Lettera Mono";
  src: url("/fonts/lettera-mono/LetteraMonoLLSub-Medium.woff2") format("woff2"),
    url("/fonts/lettera-mono/LetteraMonoLLSub-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-style: italic;
  font-family: "Lettera Mono";
  src: url("/fonts/lettera-mono/LetteraMonoLLSub-MediumItalic.woff2")
      format("woff2"),
    url("/fonts/lettera-mono/LetteraMonoLLSub-MediumItalic.woff") format("woff");
  font-display: swap;
}
