@import "solar/Tokens.scss";

$padding-x: $sol-space-xs + 7px;
$padding-y: $sol-space-xs;

.component {
  position: relative;
}

.label {
  position: absolute;
  top: $padding-y;
  right: $padding-x;
  bottom: $padding-y;
  left: $padding-x;
  z-index: $sol-z-input-label;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.input {
  position: relative;
  padding-top: $sol-space-md;
  padding-right: $padding-x;
  padding-bottom: $padding-y;
  padding-left: $padding-x;
  border: 1px solid $sol-color-gray-dark;
  border-radius: $sol-border-radius-sm;
  background-color: $sol-color-white;
  appearance: none; // Removes inner shadow for iOS

  &:focus {
    z-index: $sol-z-input-pair;
    border-color: $sol-color-black;
    box-shadow: inset 0 0 0 1px $sol-color-black;
  }

  &:not(:placeholder-shown) + .label,
  &:focus + .label {
    align-items: start;
    color: $sol-color-gray-dark;
    font-size: $sol-text-2;
  }

  &.top {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.bottom {
    margin-top: -1px; // rt: is there a better way to do this?
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.error {
    border-color: $sol-color-orange;

    &:focus {
      border-color: $sol-color-orange;
      box-shadow: inset 0 0 0 1px $sol-color-orange;
    }

    &:not(:placeholder-shown) + .label,
    &:focus + .label {
      align-items: start;
      color: $sol-color-orange;
      font-size: $sol-text-2;
    }
  }

  &.block {
    display: block;
    width: 100%;
  }
} // .input

.transparent {
  .input {
    padding-right: 0;
    padding-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid $sol-color-black;
    border-left: 0;
    border-radius: 0;
    background-color: transparent;

    &:focus {
      box-shadow: none;
    }
  }

  .label {
    right: 0;
    left: 0;
  }

  &.error:focus {
    box-shadow: none;
  }
}
