@import "solar/Tokens.scss";

.component {
  width: 100%;

  @media (min-width: $sol-breakpoint-lg) {
    width: $sol-modal-box-width-md;
  }
}

.delete {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.actions {
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: $sol-space-sm;
}
