@import "solar/Tokens.scss";

$size-button-back: 44px;

.button {
  position: relative;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: $size-button-back;
    height: $size-button-back;
    border-radius: $sol-border-radius-half;
    transform: translate(-50%, -50%);
  }
}

.link {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  font-weight: 500;
  column-gap: $sol-space-xs;
}

.listing {
  grid-template-columns: 1fr;
  width: $size-button-back;
  height: $size-button-back;
  border-radius: $sol-border-radius-half;
  background-color: $sol-color-off-white;
  place-items: center;

  svg {
    margin-left: -1px;
  }
}
