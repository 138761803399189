@import "solar/Tokens.scss";

$navbar-dot-size: 5px;

.link {
  position: relative;
  display: grid;
  height: 100%;
  color: inherit;
  font-weight: 500;
  font-size: 16px;
  place-items: center;

  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }
}

.menu {
  color: $sol-color-black;

  .link {
    justify-items: flex-start;
    padding: $sol-space-xs $sol-space-md;
  }
}

.highlighted,
.highlighted:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}
