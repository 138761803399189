@import "solar/Tokens.scss";

$size-thumbnail: 64px;

.link {
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-column-gap: $sol-space-sm;
  align-items: center;
  font-weight: 500;
}

.thumbnail {
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: $size-thumbnail;
  height: $size-thumbnail;
  border-radius: $sol-border-radius-sm;
  background: $sol-color-gray-light;
  color: $sol-color-black;
  font-weight: 400;
  font-size: $sol-title-4-size;
}
