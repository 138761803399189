@import "solar/Tokens.scss";
$size-button: 32px;

.button {
  position: relative;
  display: grid;
  min-width: 24px;
  margin-right: -$sol-space-2xs;
  cursor: pointer;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: $size-button;
    height: $size-button;
    transform: translate(-50%, -50%);
  }
}
