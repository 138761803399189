@import "solar/Tokens.scss";

$top-offset-lg: $sol-space-xl;

.component {
  width: 100%;
  margin: 0 auto;
  z-index: $sol-z-search;
  background-color: $sol-color-white;
}

.wrapper {
  position: relative;
}

.box {
  display: grid;
  position: relative;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
  border-radius: $sol-border-radius-half;
  background-color: $sol-color-white;
  color: $sol-color-black;
  border: 2px solid $sol-color-gray;

  @media (min-width: $sol-breakpoint-lg) {
    border: 2px solid $sol-color-gray;
  }
}

.icon {
  margin-left: $sol-space-xs * 1.5;
  margin-top: 2px;
}

.clear {
  width: $sol-space-sm;
  height: $sol-space-sm;
  padding: $sol-space-2xs;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $sol-border-radius-half;
  background: $sol-color-gray-light;
  position: absolute;
  right: $sol-space-xs * 1.5;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: $sol-space-sm * 1.35;
    height: $sol-space-sm * 1.35;
    transform: translate(-50%, -50%);
  }
}

.input {
  display: block;
  width: 100%;
  min-width: 100%;
  padding-top: $sol-space-xs * 1.4;
  padding-bottom: $sol-space-xs * 1.4;
  padding-right: $sol-space-lg;
  margin-left: $sol-space-2xs;
  border: 0;
  font-size: $sol-text-1;
  font-family: $sol-font-family;
  appearance: none; // Removes inner shadow for iOS
  background: transparent;
  color: $sol-color-black;

  &::placeholder {
    color: $sol-color-black;
  }
}

.bold {
  font-weight: 600;
}

.menu {
  width: 100%;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-xs;
    position: absolute;
    z-index: $sol-z-modal-dialog;
    overflow-y: scroll;
    max-height: 60vh;
    filter: $sol-shadow-dropdown;
    border-radius: $sol-border-radius-md;
    background-color: $sol-color-white;
  }
}

.list {
  padding-top: $sol-space-sm;
  padding-bottom: $sol-space-sm;
}

.pad {
  padding-top: $sol-space-xs;
  padding-right: $sol-space-sm;
  padding-bottom: $sol-space-xs;
  padding-left: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $sol-space-xs;
    padding-left: $sol-space-md;
  }
}

.chat {
  > div {
    display: inline;
  }
}

.divider {
  height: 1px;
  background-color: $sol-color-gray-light;
}

.open {
  // rt: note this is max, not min
  @media (max-width: $sol-breakpoint-lg) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;

    .scroll {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
    }

    .wrapper {
      padding-left: $sol-space-sm;
      padding-right: $sol-space-sm;
    }

    .box {
      margin-top: $sol-space-xs;
    }

    .menu {
      padding-bottom: 400px; // rt: hack to offset virtual keyboard
    }
  } // @media (max-width: $sol-breakpoint-lg)
} // .open

.home {
  position: sticky;
  top: 0;
  padding-top: $sol-space-xs;
  padding-bottom: $sol-space-xs;
  padding-left: $sol-space-sm;
  padding-right: $sol-space-sm;
  z-index: $sol-z-navbar - 1; // rt: hack to get menu to appear over search in home

  &.open {
    z-index: $sol-z-navbar + 1;
  }

  .input {
    padding-top: $sol-space-sm;
    padding-bottom: $sol-space-sm;
  }

  @media (min-width: $sol-breakpoint-lg) {
    position: absolute;
    top: $top-offset-lg - $sol-space-2xs;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: transparent;
    padding: 0;

    .icon {
      margin-left: $sol-space-md;
    }

    .input {
      padding-top: $sol-space-sm * 1.2;
      padding-bottom: $sol-space-sm * 1.2;
    }

    .box {
      filter: $sol-shadow-dropdown;
      border: 1px solid $sol-color-gray;
    }

    .wrapper {
      position: relative;
      width: 40%;
      max-width: 600px;
      margin: 0 auto;
    }
  } // @media (min-width: $sol-breakpoint-lg) {
} // .home
