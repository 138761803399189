@import "solar/Tokens.scss";

.component {
  padding-top: $sol-space-sm;
  padding-bottom: $sol-space-sm;
}

.grid {
  display: grid;
  grid-template-columns: 30% 1fr 30%;
  align-items: center;
}

.primary {
  justify-self: left;
}

.secondary {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tertiary {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  justify-self: end;
  column-gap: $sol-space-sm;
}
