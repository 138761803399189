@import "solar/Tokens.scss";

.component {
  display: block;
  width: 100%;
  padding-top: $sol-space-xs;
  padding-right: $sol-space-sm;
  padding-bottom: $sol-space-xs;
  padding-left: $sol-space-sm;
  cursor: pointer;

  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $sol-space-md;
    padding-left: $sol-space-md;
  }
}

.text {
  line-height: $sol-text-line-sm;
}

.highlighted {
  background-color: $sol-color-off-white;
}

.link {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: $sol-space-sm;
}

.icon {
  overflow: hidden;
  padding: $sol-space-xs;
  border-radius: $sol-border-radius-sm;
  background-color: $sol-color-off-white;
}

.divider {
  margin-top: $sol-space-sm;

  &:before {
    content: "";
    position: relative;
    top: -$sol-space-sm;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $sol-color-gray-light;
  }
}

.bold {
  font-weight: 600;
}

.city {
  color: $sol-color-neutral-2;
  font-size: $sol-text-3;
}
