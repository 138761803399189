@import "solar/Tokens.scss";

.component {
  display: block;
  margin-top: $sol-space-xs;
  padding: $sol-space-xs;
  border: 2px solid $sol-color-black;
  border-radius: $sol-border-radius-sm;
  font-size: $sol-text-1;
  font-family: $sol-font-family;
  appearance: none; // Removes inner shadow for iOS

  &:focus {
    border-color: $sol-color-sky;
  }
}

.error {
  border-color: $sol-color-orange;

  &:focus {
    border-color: $sol-color-orange;
  }
}

.block {
  display: block;
  width: 100%;
}
