@import "solar/Tokens.scss";

.component {
  // rt: added to fix child image links
  display: inline-grid;
  line-height: 0;
}

.link {
  color: inherit;

  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }
}
