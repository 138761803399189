@import "solar/Tokens.scss";

.component {
  padding-top: $sol-space-md;
}

.bordered {
  padding-bottom: $sol-space-md;
  border-bottom: 1px solid $sol-color-gray-light;
}
