@import "solar/Tokens.scss";

.component {
  display: grid;
  margin-top: $sol-space-md;

  @media (min-width: $sol-breakpoint-lg) {
    grid-template-columns: auto auto auto auto 1fr;
    margin-top: $sol-space-xl;
  }
}

.default {
  @media (min-width: $sol-breakpoint-lg) {
    column-gap: $sol-space-xl;
  }
}

.wide {
  @media (min-width: $sol-breakpoint-lg) {
    column-gap: $sol-space-2xl;
  }
}
