@import "solar/Tokens.scss";

.modal {
  grid-template-rows: 1fr;
  background: $sol-color-lime;
}

.grid {
  display: grid;
}

.body {
  padding-top: $sol-space-lg;
  padding-bottom: $sol-space-lg;

  @media (min-width: $sol-breakpoint-lg) {
    max-width: calc($sol-modal-box-max-width / 2);
    padding-top: $sol-space-xl;
    padding-bottom: $sol-space-sm;
  }
}

.input {
  border-color: darken($sol-color-lime, 5%);
}

.title {
  text-align: center;
}

.text {
  margin-top: $sol-space-xs;
  text-align: center;
}

.code {
  margin-top: $sol-space-sm;
  text-align: center;
}

.form {
  margin-top: $sol-space-sm;
}

.action {
  margin-top: $sol-space-sm;
}

.footer,
.footer a {
  color: $sol-color-gray-darker;
}

.footer {
  margin-top: $sol-space-sm;
  text-align: center;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-lg;
  }
}
