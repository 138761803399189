@import "solar/Tokens.scss";

.component {
  padding-bottom: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    padding-bottom: $sol-space-md;
  }
}

.bordered {
  padding-top: $sol-space-sm;
  border-top: 1px solid $sol-color-gray-light;

  @media (min-width: $sol-breakpoint-lg) {
    padding-top: $sol-space-md;
  }
}
