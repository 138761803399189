@import "solar/Tokens.scss";

.component {
  line-height: $sol-text-line-sm;
}

.lettera-text {
  font-weight: 700;
}

.cushing {
  font-family: $sol-font-family-serif;
}

.title-1 {
  font-size: $sol-title-1-size;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: $sol-title-1-size-responsive;
  }
}

.title-2 {
  font-size: $sol-title-2-size;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: $sol-title-2-size-responsive;
  }
}

.title-3 {
  font-size: $sol-title-3-size;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: $sol-title-3-size-responsive;
  }
}

.title-4 {
  font-size: $sol-title-4-size;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: $sol-title-4-size-responsive;
  }
}

.title-5 {
  font-size: $sol-title-5-size;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: $sol-title-5-size-responsive;
  }
}
