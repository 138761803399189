@import "solar/Tokens.scss";

.component {
  display: block;
  margin-top: $sol-space-xs;
  color: $sol-color-gray-darker;
  font-size: $sol-text-2;
}

.error {
  color: $sol-color-orange;
}
