@import "solar/Tokens.scss";

.component {
  strong {
    font-weight: 600;
  }

  i {
    font-style: italic;
  }

  a {
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.text-1 {
  font-size: $sol-text-1;
  line-height: $sol-text-line-base;
}

.text-2 {
  font-size: $sol-text-2;
  line-height: $sol-text-line-base;
}

.text-3 {
  font-size: $sol-text-3;
  line-height: $sol-text-line-base;
}

.text-4 {
  font-size: $sol-text-4;
  line-height: $sol-text-line-base;
}

.mono {
  font-weight: 500;
  font-family: $sol-font-family-mono;
  letter-spacing: 1px;
  text-transform: uppercase;
}
