@import "solar/Tokens.scss";

.item {
  display: block;
  padding: $sol-space-xs $sol-space-md;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    background: $sol-color-neutral-1;
    text-decoration: none;
  }
}

.highlight {
  font-weight: 500;
}
