@import "solar/Tokens.scss";

.component {
  padding-top: $sol-space-md;
  padding-bottom: $sol-space-md;
  background-color: $sol-color-off-white;
  color: $sol-color-black;

  @media (min-width: $sol-breakpoint-lg) {
    padding-top: $sol-space-xl;
    padding-bottom: $sol-space-xl;
  }
}

.dividerTop {
  height: 1px;
  margin-top: $sol-space-md;
  margin-bottom: $sol-space-md;
  background-color: $sol-color-gray-light;

  @media (min-width: $sol-breakpoint-lg) {
    margin-bottom: $sol-space-xl;
  }
}

.dividerBottom {
  height: 1px;
  margin-top: $sol-space-md;
  margin-bottom: $sol-space-md;
  background-color: $sol-color-gray-light;

  @media (min-width: $sol-breakpoint-lg) {
    margin-top: $sol-space-xl;
  }
}

.slim {
  padding-top: $sol-space-md;
  padding-bottom: $sol-space-md;
}
