@import "solar/Tokens.scss";

.link {
  display: block;
  padding-top: $sol-space-xs;
  padding-bottom: $sol-space-xs;
}

.primary {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
  padding-top: $sol-space-xs;
  padding-bottom: $sol-space-xs;
  text-align: left;

  .text {
    font-size: 19px;
  }
}
