@import "../Tokens.scss";

.component {
  position: relative;
  width: 100%;
  max-width: $sol-breakpoint-xl;
  margin: 0 auto;
  padding-right: $sol-space-sm;
  padding-left: $sol-space-sm;

  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $sol-space-xl;
    padding-left: $sol-space-xl;
  }
}

.fluid {
  max-width: none;

  @media (min-width: $sol-breakpoint-lg) {
    padding-right: $sol-space-sm;
    padding-left: $sol-space-sm;
  }
}

.wide {
  max-width: $sol-breakpoint-2xl;
}
